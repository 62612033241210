import { request } from '@/utils/http'
export const getWeather = (marketId) => {
    return request({
        url: '/cms/guideversion/dp/getWeather?marketId=' + marketId,
        method: 'get'
    })
}

export const getDetail = (marketId) => {
    return request({
        url:  '/cms/guideversion/dp/data/' + marketId,
        method: 'get'
    })
}

export const getVersion = (marketId) => {
    return request({
        url:  '/cms/guideversion/dp/version/' + marketId,
        method: 'get'
    })
}
